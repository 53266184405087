.Shadow {
  position: absolute;
  bottom: 25vh;
  left: 20vw;
  font-size: 8em;
  font-family: SummerLovingSans;
}

.Name {
  position: absolute;
  bottom: 28vh;
  left: 20vw;
  font-size: 7em;
  font-family: SummerLoving;
  /*transform: rotate(-2deg);*/
}

.Name > a {
  text-decoration: none;
  /*color: rgb(1,13,55);*/
  color: #EA00A5;
  letter-spacing: .01em;
  text-shadow: -2px 2px 4vh #EA3A00;
  opacity: 70%;
}

.Shadow > a {
  text-decoration: none;
  opacity: 15%;
  color: #EA00A5;
}

.Home {
  min-height: calc(100vh - 7vh);
  height: 100%;
  background-color: #150033;
  align-items: right;
  justify-content: top;
  font-family: CompitaMedium;
  flex: auto;
}

.Nav {
  position: absolute;
  top: 14vh;
  right: 20vw;
  font-size: 2.5vh;
}

.Nav > a {
  display: inline;
  padding-left: 3vw; 
  text-decoration: none;
  color: #EA00A5;
  font-family: CompitaMedium;
}

.Nav > a:hover{
  color: #EA3A00;
}

.HomeNav > a {
  position: relative;
  top: 10vh;
  font-size: 1em;
  display: flex;
  justify-content: right;
  right: 7vw;
  padding-bottom: .75vh;
  color: rgb(117, 160, 222);
  z-index: 100;
  text-decoration: none;
}

.HomeNav > a:hover{
  color: #EA00A5;
}

@media only screen and (min-width: 600px) {
  .Home {
    min-height: calc(100vh - 7vh);
    background-color: rgb(1,2,7);
  }

  .Name {
    top: 15vh;
    display: flex;
    justify-content: center;
    line-height: .6em;
  }  
}

@media only screen and (max-width: 600px) {
  .Home {
    min-height: calc(100vh - 7vh - 9vh);
    background-color: rgb(1,2,7);
  }

  .Name {
    top: 15vh;
    display: flex;
    justify-content: center;
    line-height: .6em;
  }  
  
  .HomeNav {
    height: 10vh;
  }
  
  .HomeNav > a {
    position: relative;
    top: 53vh;
    font-size: 1em;
    display: flex;
    justify-content: center;
    padding-bottom: 1.3vh;
    font-size: 1.2em;
    right: 0;
  }

  img {
    position: absolute;
    top: 15vh;
    width: 100vw;
    height: auto;
  }
}

img {
  position: absolute;
  bottom: 0vh;
  display: flex;
  left: 7.5vw;
  right: 7.5vw;
  width: 85vw;
  height: auto;
}

.Content {
  min-height: calc(100vh - 7vh);
  height: 100%;
  background-color: #D4DFFF;
  align-items: right;
  justify-content: top;
  font-family: CompitaMedium;
  flex: auto;
}

@media only screen and (max-width: 600px) {
  .Content {
    min-height: calc(100vh - 7vh - 9vh);
  }
}

.Content > p {
  display: flex;
  justify-content: center;
}

.Content > .HomeIcon {
  position: absolute;
  top: 14vh;
  left: 20vw;
}

hr {
  width: 60vw;
}

.LastEntry {
  padding-bottom: 10vh;
}

.Filler {
  background-color: #D4DFFF;
  height: 28vh;
}

.Title {
  width: 100vw;
  font-size: 6em;
  display: flex;
  justify-content: center;
  font-family: SummerLoving;
  color: #EA3A00;
  text-shadow: 0px 0px 2vh #FF67D2;
  padding-top: 30vh;
  padding-bottom: 10vh;
}

@media only screen and (max-width: 600px) {
  .Title {
    padding-top: 25vh;
    padding-bottom: 7vh;
    font-size: 5em;
  }
}

h3 {
  font-family: SummerLovingSans;
  font-size: 2.5em;
  padding-left: 20vw;
  color: #EA00A5;
}

@media only screen and (max-width: 600px) {
  h3 {
    padding-left: 10vw;
    margin-bottom: 0;
  }
}

.Content > p {
  font-family: CompitaMedium;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20vw;
  padding-right: 20vw;
  line-height: 1.5em;
  color: #3C3C3C;
}

@media only screen and (max-width: 600px) {
  .Content > p {
    padding-left: 10vw;
    padding-right: 10vw;
    line-height: 1.4em;
  }
}

.Photo {
  background-color: #8E1AB8;
  opacity: 50%;
  margin-top: 7vh;
  margin-right: 15vw;
  width: 45vw;
  height: 40vh;
}

.Subtitle {
  position: absolute;
  top: 40vh;
  text-align: center;
  width: 100vw;
  font-size: 1.2em;
  color: #3C3C3C;
  font: CompitaMedium
}

@media only screen and (max-width: 600px) {
  .Subtitle {
    top: 33vh;
    font-size: 1em;
  }
}

.CopyLink {
  display: inline;
  position: relative;
  padding-left: 2vw;
}

@media only screen and (min-width: 600px) {
  .About {
    top: 33vh;
    width: 60vw;
    font-size: 1em;
  }

  img {
    width: 60vw;
    right: 20vw;
    left: 20vw;
    position: relative;
  }
}


footer {
  margin-top: 0;
  height: 7vh;
  /*background-color: #25005B;*/
  /*background-color: #19003D;*/
  background-color: rgb(1,13,55);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5vh;
  position: relative;
  width: 100%;
  font-family: CompitaMedium;
}

.Byline {
  position: absolute;
  align-items: right;
  padding-left: 20vw;
  left: 0;
  font-size: .75em;
  color: #FBFBFB;
}

@media only screen and (max-width: 600px) {
  .Byline {
    padding-left: 5vw;
  }
}

.Icons > a {
  padding-left: 2vw;
}

.Copyright {
  position: absolute;
  align-items: right;
  padding-right: 20vw;
  right: 0;
  font-size: .75em;
  color: #FBFBFB;
}

@media only screen and (max-width: 600px) {
  .Copyright {
    padding-right: 5vw;
  }
}

.Reference {
  text-decoration: none;
}

.Gallery > img {
  position: relative;
  display: flex;
  top: 3vh;
  right: 25vw;
  left: 25vw;
  width: 50vw;
}

.Caption {
  position: relative;
  top: 3vh;
  left: 25vw;
  right: 25vw;
  width: 50vw;
}

.Caption > a {
  text-decoration: none;
  color: #EA00A5;
}

@media only screen and (max-width: 600px) {
  .Gallery > img {
    position: relative;
    display: flex;
    top: 3vh;
    bottom: 3vh;
    right: 10vw;
    left: 10vw;
    width: 80vw;
  }

  .Caption {
    position: relative;
    width: 80vw;
    left: 10vw;
    right: 10vw;
    top: 3vh;
  }
  
  .Caption > a {
    text-decoration: none;
    color: #EA00A5;
  }

  img {
    width: 80vw;
    right: 10vw;
    left: 10vw;
    position: relative;
    top: -1vh;
    bottom: 1vh;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 59%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  right: 20vw;
  left: 20vw;
  width: 60%;
  height: 60%;
  position: absolute;
}

@media only screen and (max-width: 600px) {
  .video-responsive {
    overflow: hidden;
    padding-bottom: 59%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 12.5vw;
    right: 12.5vw;
    width: 75%;
    height: 75%;
    position: absolute;
  }

  .Content > .HomeIcon {
    position: absolute;
    top: 10vh;
    left: 10vw;
  }

  .Nav {
    position: absolute;
    top: 17vh;
    right: 10vw;
    font-size: 2.5vh;
  }
  
  .Nav > a {
    display: inline;
    padding-left: 3vw; 
    text-decoration: none;
    color: #EA00A5;
    font-family: CompitaMedium;
  }
}


