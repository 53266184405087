body {
  margin: 0;
  background-color: #D4DFFF;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'SummerLoving';
  src: local('SummerLoving'), url(./fonts/SummerLoving.otf) format('truetype');
}

@font-face {
  font-family: 'SummerLovingSans';
  src: local('SummerLovingSans'), url(./fonts/SummerLovingSans.otf) format('truetype');
}

@font-face {
  font-family: 'CompitaLight';
  src: local('CompitaLight'), url(./fonts/CompitaLight.otf) format('truetype');
}

@font-face {
  font-family: 'CompitaRegular';
  src: local('CompitaRegular'), url(./fonts/CompitaRegular.otf) format('truetype');
}

@font-face {
  font-family: 'CompitaMedium';
  src: local('CompitaMedium'), url(./fonts/CompitaMedium.otf) format('truetype');
}

